import React from 'react'
import Classes from './Home.module.css'
const image = '/SilberHintergrund.png'

export default function SectionC() {
    return (
        <div className={Classes.sectionC} >
            <h1>
                Unser <br /> Spielfeld
            </h1>
            <div className={Classes.miniContainer} >
                <div className={Classes.box1} >
                    <div className={Classes.para} >
                        <h4>Analyse</h4>
                        <h5>
                        Jeder Spieler absolviert eine detaillierte Analyse, bei der Feinheiten präzise erfasst und positionsbezogen eingeordnet werden. Schwächen und Stärken werden durch Zahlen belegt. Auf dieser Basis erstellen wir aussagekräftige Profile, was es euren Trainern ermöglicht, den Kader optimal zusammenzustellen.
                        </h5>
                    </div>
                    <div className={Classes.para} >
                        <h4>Entwicklung</h4>
                        <h5>
                        Auf Grundlage der erhobenen Daten entwickeln wir präzise und klare Entwicklungspläne für Spieler und Mannschaft. So setzen wir messbare Ziele und definieren Etappen, die erreicht werden müssen, um den maximalen Erfolg zu erzielen und langfristig zu sichern.
                        </h5>
                    </div>

                </div>
                <div className={Classes.box2} >
                    <div className={Classes.box2MiniCon} >
                        <img src={image} alt='image' />
                    </div>
                </div>
                <div className={Classes.box3} >
                    <div className={Classes.para} >
                        <h4>Erfolg</h4>
                        <h5>
                        Wir haben unseren Ursprung im Leistungssport und möchten euch diese Professionalität ermöglichen. Jeder Verein hat seine individuelle Ziele, die es zu erreichen gilt und wir brennen dafür euch auf diesem Weg zu unterstützen. Der Erfolg steht und fällt mit der Qualität der Betreuung und um diese auf das höchste Niveau zu heben, sind wir der ideale Ansprechpartner.
                        </h5>
                    </div>
                </div>
            </div>

        </div>
    )
}
