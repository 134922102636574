import React, { useEffect, useState } from 'react';
import Classes from './About.module.css';
const backgroundImg = '/about.png';

export default function SectionA() {

    return (
        <div className={Classes.sectionA}>
            <img src={backgroundImg} alt='background' className={Classes.section_img} />
            <div className={Classes.hero1Con}>
                <h1>UNSER TEAM</h1>
                <h5>
                    Unsere Expertise 
                </h5>
            </div>
        </div>
    );
}
